import type { Agency, AgencyInformation } from '@/javascript/interfaces'

import type { ApiClient } from '../api'
import type {
	PlanInformation,
	StripeCheckout,
	Subscription,
	SubscriptionPreview,
	UserSeating,
} from './interfaces'

export async function getTrialDetails(
	api: ApiClient,
	id: string): Promise<ApiGetTrialDetailsResponse> {
	const path = `/api/agencies/${id}/sales/trial_details`
	const response = await api.get<ApiGetTrialDetailsResponse>(path)

	return response.data
}

export async function postCreateDraftSubscription(
	api: ApiClient,
	id: string): Promise<ApiDraftSubscriptionResponse> {
	const path = `/api/agencies/${id}/sales/create_draft_subscription`
	const response = await api.post<ApiDraftSubscriptionResponse>(path)

	return response.data
}

export async function postRequestMoreSeats(
	api: ApiClient,
	path: string
): Promise<void> {
	await api.post(path)
}

export async function putSubscriptionPreview(
	api: ApiClient,
	path: string,
	payload: ApiSubscriptionPayload
): Promise<ApiSubscriptionPreviewResponse> {
	const response = await api.put<ApiSubscriptionPreviewResponse>(path, payload)

	return response.data
}

export async function putUpdateDraftSubscription(
	api: ApiClient,
	path: string,
	payload: ApiDraftSubscriptionPayload
): Promise<ApiSubscriptionResponse> {
	const response = await api.put<ApiSubscriptionResponse>(path, payload)

	return response.data
}

export async function putUpdateSubscription(
	api: ApiClient,
	path: string,
	payload: ApiSubscriptionPayload
): Promise<string> {
	const { data } = await api.put<{ message: string }>(path, payload)

	return data.message
}

export async function putActivateSubscription(
	api: ApiClient,
	path: string,
	payload: ApiSubscriptionPayload
): Promise<string> {
	const { data } = await api.put<{ message: string }>(path, payload)

	return data.message
}

export interface ApiActivateSubscriptionPayload {
	added_seats: number
	ordway_subscription_id: string
	plan_id: string
	stripe_setup_intent_id: string
}

export interface ApiDraftSubscriptionPayload {
	added_seats: number
	ordway_subscription_id: string
	plan_id: string
}

export interface ApiSubscriptionPayload {
	added_seats: number
	ordway_subscription_id: string
}

export interface ApiSubscriptionResponse {
	data: {
		ordway_subscription: Subscription
	}
}

export interface ApiSubscriptionPreviewResponse {
	data: {
		agency: Agency
		ordway_subscription: Subscription
		ordway_subscription_preview: SubscriptionPreview
	}
}

export interface ApiDraftSubscriptionResponse {
	data: {
		agency: Agency
		ordway_subscription: Subscription
		plans: PlanInformation[]
		stripe: StripeCheckout
		user_seating: UserSeating
	}
}

export interface ApiGetTrialDetailsResponse {
	data: {
		agency: AgencyInformation
	}
}
