import { CONFIG_HEADER } from '@/javascript/vuejs/helpers/_constants'

import type { ApiClient, ResponseData } from '../api'
import {
	apiRouteBuilder,
	postMemberTags,
	postResendWelcomeMessage,
	postSendPasswordDetails,
	putGroupAssignment,
} from '../apiRoutes'
import { getDeactivatedMemberList } from '../apiRoutes'
import type { Member, MemberDetails } from '../members/interfaces'
import type { Note } from './interfaces'

export async function getDeactivatedMembers(api: ApiClient, agencyId: number):
Promise<ApiGetDeactivatedResponse> {
	const { data } = await api.get<ApiGetDeactivatedResponse>(getDeactivatedMemberList, { id: agencyId })

	return data
}

export async function getMemberDetails(api: ApiClient, route: string): Promise<MemberDetails> {
	const { data } = await api.get<MemberDetails>(route)

	return data
}

export async function postChangePassword(
	api: ApiClient,
	route: string,
	payload: ApiPostChangePasswordPayload
): Promise<ResponseData> {
	const response: ResponseData = await api.post(route, { caregiver_id: payload.memberId, password: payload.password }, CONFIG_HEADER)

	return response
}

export async function postMemberAction(
	api: ApiClient,
	route: string,
	payload: ApiPostActionPayload
): Promise<ResponseData> {
	const response: ResponseData = await api.post(route, { caregiver_ids: payload.memberIds }, CONFIG_HEADER)

	return response
}

export async function postNotes(api: ApiClient, route: string, { memberId, notes }: ApiPostNotesOptions): Promise<void> {
	await api.post<void>(
		route,
		{
			caregiver_id: memberId,
			notes,
		}
	)
}

export async function postResendMemberWelcomeMessage(api: ApiClient, payload: ApiPostProfilePayload): Promise<void> {
	await api.post(postResendWelcomeMessage, { caregiver_id: payload.memberId })
}

export async function postSendPasswordResetDetails(api: ApiClient, payload: ApiPostProfilePayload): Promise<void> {
	await api.post(postSendPasswordDetails, { caregiver_id: payload.memberId })
}

export async function postSendTextMessage(
	api: ApiClient,
	route: string,
	payload: ApiPostSendTextMessagePayload
): Promise<ApiPostSendTextMessageResponse> {
	const response = await api.post<ApiPostSendTextMessageResponse>(route, {
		add_all_caregivers: payload.addAllMembers,
		caregiver_ids: payload.memberIds,
		groupId: payload.groupId,
		message: payload.message,
	})

	return response.data
}

export async function postTag(api: ApiClient, tagName: string): Promise<void> {
	await api.post<void, ApiPostTagPayload>(postMemberTags, { tag_name: tagName })
}

export async function postTransferMember(
	api: ApiClient,
	route: string,
	payload: ApiPostTransferPayload
): Promise<ResponseData> {
	const response: ResponseData = await api.post(route, {
		caregiver_id: payload.memberId,
		new_agency_id: payload.newAgencyId,
	}, CONFIG_HEADER)

	return response
}

export async function putMoveToGroup(
	api: ApiClient,
	payload: ApiPutMoveToGroupPayload
): Promise<void> {
	const route = apiRouteBuilder(putGroupAssignment, { agencyId: payload.agencyId })

	await api.put(route, {
		agency_id: payload.agencyId,
		caregiver_group_id: payload.groupId,
		caregiver_ids: payload.memberIds,
	})
}

export interface ApiGetDeactivatedResponse {
	caregivers: Member[],
	softwareProviderName: string,
}

export interface ApiPostActionPayload {
	memberIds: number[]
}

export interface ApiPostChangePasswordPayload {
	memberId: string
	password: string
}

export interface ApiPostNotesOptions {
	memberId: number
	notes: Note[]
}

export interface ApiPostNotePayload extends ApiPostProfilePayload {
	notes: Note[]
}

export interface ApiPostProfilePayload {
	memberId: number
}

export interface ApiPostSendTextMessagePayload {
	addAllMembers?: boolean
	groupId?: number
	memberIds?: string
	message: string
}

export interface ApiPostSendTextMessageResponse {
	error: string | null,
	message: string | null,
	updated_caregivers: Record<number, number | string>[][]
}

export interface ApiPostTagPayload {
	tag_name: string
}

export interface ApiPostTransferPayload extends ApiPostProfilePayload {
	newAgencyId: number | undefined
}

export interface ApiPutMoveToGroupPayload {
	agencyId: number
	groupId: number | undefined
	memberIds: number[]
}
