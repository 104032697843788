/**
 * Deep clone an array
 *
 * @example deepClone(array)
 * @param array
 * @returns [array]
 */
export function deepClone<T>(array: T[]): T[] {
	return JSON.parse(JSON.stringify(array))
}

/**
 * Deep clone an object
 *
 * @example deepCloneObject(object)
 * @param source
 * @returns object
 */
export function deepCloneObject<T>(source: T): T {
	return JSON.parse(JSON.stringify(source))
}
