import type { AxiosError, AxiosResponse } from 'axios'

import type {
	ApiPagination,
	ApiSortBy,
	SortDirection,
} from '@/javascript/interfaces'

import type { ApiClient, ResponseData } from '../api'
import { getEnrolledCourses } from '../apiRoutes'
import type { Course } from '../goals/interfaces'
import type {
	Member,
	MemberFilter,
	MemberFormData,
} from './interfaces'

export async function getAutoEnrolledCourses(
	api: ApiClient,
	payload: ApiAutoEnrolledCoursesPayload
): Promise<Course[]> {
	const { data } = await api.get<ApiAutoEnrolledCoursesResponse>(getEnrolledCourses, payload)

	return data.courses
}

export async function getMembers(api: ApiClient, agencyId: number, options?: ApiPaginationOptionsPayload):
Promise<ApiMembersResponse> {
	const response = await api.get<ApiMembersResponse>(`/api/agencies/${agencyId}/caregivers`, { ...options })

	return response.data
}

export async function postAddMember(
	api: ApiClient,
	path: string,
	payload: MemberFormData
): Promise<ResponseData> {
	const response = await api.post<AxiosError | AxiosResponse>(path, payload)

	return response
}

export async function postVerifyMember(
	api: ApiClient,
	path: string,
	payload: MemberFormData
): Promise<ResponseData> {
	const response = await api.post<AxiosError | AxiosResponse>(path, payload)

	return response
}

export async function putEditMember(
	api: ApiClient,
	path: string,
	payload: MemberFormData
): Promise<ResponseData> {
	const response = await api.put<AxiosError | AxiosResponse>(path, payload)

	return response
}

export async function putUpdateAnnualYear(
	api: ApiClient,
	payload: { path: string, year: number }
): Promise<ResponseData> {
	const response = await api.put<AxiosError | AxiosResponse>(payload.path, { year: payload.year })

	return response
}
export interface ApiMembersResponse {
	data: Member[]
	pagination: ApiPagination
	sort: ApiSortBy
}

export interface ApiAutoEnrolledCoursesPayload {
	caregiver_group_id: number | undefined
	format: 'html' | 'json'
	id: number
	initial_training: boolean
}

export interface ApiAutoEnrolledCoursesResponse { courses: Course[] }

interface ApiPaginationOptionsPayload {
	filter: MemberFilter
	group_id: number | null
	page: number
	per_page: number
	query: string
	sort_by: SortBy
	sort_direction: SortDirection
}

export type SortBy
	= ''
	| 'annual-due-date'
	| 'annual-progress'
	| 'initial-due-date'
	| 'initial-progress'
	| 'name'
