// date key and separator character checks
export const validSeparatorsRegex = /[-.\/]/ // matches "-", ".", "/"
// "Enter" is allowed as a valid key because it's often used to submit forms from within a text input, so we don't want to preventDefault() on it
export const validKeysArray = [
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'/',
	'-',
	'.',
	' ',
	'Enter',
]
export const invalidKeysRegex = /[^-.\/\d]/g
// storing Enter keyCode value as a constant
export const enterKeyCode = 'Enter'

// error messages for invalid dates
export const defaultErrorMessages: Record<DateErrorType, string> = {
	date: 'Date must be in the format MM/DD/YYYY',
	day: 'Day is invalid',
	hidden: '',
	max: 'This date is too far in the future',
	min: 'This date is too far in the past',
	month: 'Month is invalid',
	year: 'Year is invalid',
}

export type DateErrorType =
	| 'date'
	| 'day'
	| 'hidden'
	| 'max'
	| 'min'
	| 'month'
	| 'year'
