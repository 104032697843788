import numberToWords from 'number-to-words'
import Vue from 'vue'

import type { Agency } from '@/javascript/interfaces'
import { STATUS_CODE } from '@/javascript/vuejs/helpers/_constants'

import { FREQUENCY, TIME } from '../helpers/_constants'
import type { LastPrinted } from '../services/administratorDashboard/interfaces'
import api from '../services/api'
import type {
	AnnualTrainingSchedule,
	ComplianceFrequency,
	Course,
	Curriculum,
	CurriculumData,
	CurriculumError,
	CurriculumTrack,
	Path,
	Requirements,
	RequirementsWithErrors,
	ScheduleData,
	ScheduleValue,
	Topic,
	TopicRequirements,
} from '../services/courses/interfaces'
import {
	type ApiPublishCurriculumPayload,
	type ApiPublishOneoffCurriculumPayload,
	type ApiTrackCreateCopyPayload,
	deleteTrack,
	postCurriculums,
	postTrackCopy,
	putOneoffPublishCurriculum,
	putPublishCurriculum,
	putTrackEdit,
} from '../services/curriculums'
import GlobalStore from '../stores/GlobalStore'
import { deepClone } from '../utils/deepClone'
import { getAgencyId } from '../utils/getAgencyId'
import { capitalize } from '../utils/stringUtils'

const state = Vue.observable({
	additionalCurriculumData: {} as CurriculumData,
	agency: {} as Agency,
	annualFrequencyValue: null as number | null,
	canChangeCurriculum: false,
	cantChangeCurriculumWarningText: '',
	currentStep: 1,
	curriculumCount: 0,
	curriculumFrequency: '',
	curriculumId: null as number | null,
	curriculumIsDefault: false,
	curriculumName: '',
	curriculumTracks: [] as CurriculumTrack[],
	curriculumType: 0,
	curriculums: [] as Curriculum[],
	deletedCurriculums: [] as Curriculum[],
	frequency: null as ComplianceFrequency | number | null,
	isCertifications: false,
	isConfiguration: false,
	isForCompliance: false,
	isModified: false,
	isReordering: false,
	lastPrinted: {} as LastPrinted,
	noticeText: '',
	originalCurriculums: [] as Curriculum[],
	paths: {} as Path,
	publishingCurriculum: false,
	reordered: false,
	requestInProgress: false,
	requirements: {} as Requirements,
	scheduleData: {
		classes_count: FREQUENCY.CLASSES_COUNT,
		frequency_count: FREQUENCY.COUNT,
		frequency_type: 'months',
	} as ScheduleData,
	scheduleValues: {
		immediately: '',
		spread: '',
		when_due_soon: '',
	} as ScheduleValue,
	selectedCurriculum: {} as Curriculum,
	selectedCurriculumTrack: {} as CurriculumTrack,
	selectedScheduleTiming: '' as AnnualTrainingSchedule,
	selectedTopicName: '' as number | string,
	sidebarCourses: [] as Course[],
	topics: [] as Topic[],
	totalSteps: 2,
})

const store = {
	// Getters and Setters
	get additionalCurriculumData(): CurriculumData {
		return state.additionalCurriculumData
	},
	set additionalCurriculumData(value) {
		state.additionalCurriculumData = value
	},

	get agency(): Agency {
		return state.agency
	},
	set agency(value) {
		state.agency = value
	},

	get annualFrequencyValue(): number | null {
		return state.annualFrequencyValue
	},
	set annualFrequencyValue(value) {
		state.annualFrequencyValue = value
	},

	get canChangeCurriculum(): boolean {
		return state.canChangeCurriculum
	},
	set canChangeCurriculum(value) {
		state.canChangeCurriculum = value
	},

	get cantChangeCurriculumWarningText(): string {
		return state.cantChangeCurriculumWarningText
	},
	set cantChangeCurriculumWarningText(value) {
		state.cantChangeCurriculumWarningText = value
	},

	get complianceRequirements(): RequirementsWithErrors {
		const requirements = state.requirements

		return Object.entries(requirements).length && state.frequency !== 'oneoff'
			? {
				...state.requirements,
				topicErrors: state.curriculums.reduce((acc, cur) => [...acc, ...cur.errors ?? []],
					[] as CurriculumError[]),
			}
			: {
				...state.requirements,
				topicErrors: [],
			}
	},

	get currentStep(): number {
		return state.currentStep
	},
	set currentStep(value) {
		state.currentStep = value
	},

	get curriculumCount(): number {
		return state.curriculumCount
	},
	set curriculumCount(value) {
		state.curriculumCount = value
	},

	get curriculumFrequency(): string {
		return state.curriculumFrequency
	},
	set curriculumFrequency(value) {
		state.curriculumFrequency = value
	},

	get curriculumId(): number | null {
		return state.curriculumId
	},
	set curriculumId(value) {
		state.curriculumId = value
	},

	get curriculumIsDefault(): boolean {
		return state.curriculumIsDefault
	},
	set curriculumIsDefault(value) {
		state.curriculumIsDefault = value
	},

	get curriculumName(): string {
		return state.curriculumName
	},
	set curriculumName(value) {
		state.curriculumName = value
	},

	get curriculumTracks(): CurriculumTrack[] {
		return state.curriculumTracks
	},
	set curriculumTracks(value) {
		state.curriculumTracks = value
	},

	get curriculumType(): number {
		return state.curriculumType
	},
	set curriculumType(value) {
		state.curriculumType = value
	},

	get curriculums(): Curriculum[] {
		return state.curriculums
	},
	set curriculums(value) {
		state.curriculums = value
	},

	get deletedCurriculums(): Curriculum[] {
		return state.deletedCurriculums
	},
	set deletedCurriculums(value) {
		state.deletedCurriculums = value
	},

	get frequency(): ComplianceFrequency | number | null {
		return state.frequency
	},
	set frequency(value) {
		state.frequency = value
	},

	get hasRequirements(): boolean {
		return !!(this.complianceRequirements?.time || this.complianceRequirements?.topics?.length)
	},

	get isCertifications(): boolean {
		return state.frequency === 'oneoff'
	},

	get isConfiguration(): boolean {
		return state.isConfiguration
	},
	set isConfiguration(value) {
		state.isConfiguration = value
	},

	get isForCompliance(): boolean {
		return state.isForCompliance
	},
	set isForCompliance(value) {
		state.isForCompliance = value
	},

	get isModified(): boolean {
		return this.isModifiedCurriculum()
	},

	get isReordering(): boolean {
		return state.isReordering
	},
	set isReordering(value) {
		state.isReordering = value
	},

	get lastPrinted(): LastPrinted {
		return state.lastPrinted
	},
	set lastPrinted(value) {
		state.lastPrinted = value
	},

	get noticeText(): string {
		return state.noticeText
	},
	set noticeText(value) {
		state.noticeText = value
	},

	get originalCurriculums(): Curriculum[] {
		return state.originalCurriculums
	},
	set originalCurriculums(value) {
		state.originalCurriculums = value
	},

	get paths(): Path {
		return state.paths
	},
	set paths(value) {
		state.paths = value
	},

	get publishingCurriculum(): boolean {
		return state.publishingCurriculum
	},
	set publishingCurriculum(value) {
		state.publishingCurriculum = value
	},

	get reordered(): boolean {
		return state.reordered
	},
	set reordered(value) {
		state.reordered = value
	},

	get requestInProgress(): boolean {
		return state.requestInProgress
	},
	set requestInProgress(value) {
		state.requestInProgress = value
	},

	get requirements(): Requirements {
		return state.requirements
	},
	set requirements(value) {
		state.requirements = value
	},

	get scheduleData(): ScheduleData {
		return state.scheduleData
	},
	set scheduleData(value) {
		state.scheduleData = value
	},

	get scheduleValues(): ScheduleValue {
		return state.scheduleValues
	},
	set scheduleValues(value) {
		state.scheduleValues = value
	},

	get selectedCurriculum(): Curriculum {
		return state.selectedCurriculum
	},
	set selectedCurriculum(value) {
		state.selectedCurriculum = value
	},

	get selectedCurriculumTrack(): CurriculumTrack {
		return state.selectedCurriculumTrack
	},
	set selectedCurriculumTrack(value) {
		state.selectedCurriculumTrack = value
		state.curriculumId = value.id
		state.curriculumName = value.name
	},

	get selectedScheduleTiming(): AnnualTrainingSchedule {
		return state.selectedScheduleTiming
	},
	set selectedScheduleTiming(value) {
		state.selectedScheduleTiming = value
	},

	get selectedTopicName(): number | string {
		return state.selectedTopicName
	},
	set selectedTopicName(value) {
		state.selectedTopicName = value
	},

	get sidebarCourses(): Course[] {
		return state.sidebarCourses
	},
	set sidebarCourses(value) {
		state.sidebarCourses = value
	},

	get topics(): Topic[] {
		return state.topics
	},
	set topics(value) {
		state.topics = value
	},

	get totalSteps(): number {
		return state.totalSteps
	},
	set totalSteps(value) {
		state.totalSteps = value
	},

	// Actions
	addCurriculum(frequency: ComplianceFrequency | number | null): void {
		let curriculumToAdd

		// Add new blank curriculum
		if (!state.curriculums.length) {
			curriculumToAdd = {
				agency_id: getAgencyId(),
				caregiver_type: 0,
				courses: [],
				created_at: '',
				curriculumHeader: this.getCurriculumHeader(frequency, 1),
				curriculum_track_id: state.curriculumId,
				curriculum_type: state.curriculumType,
				description: null,
				duration: 0,
				errors: [],
				frequency,
				id: null,
				is_default: false,
				name: state.curriculumName,
				pricing_tier: GlobalStore.agency.pricing_tier,
				released_at: '',
				sequence: 1,
				updated_at: '',
			}
			// Duplicate previous curriculum
		} else {
			const id = null
			const lastCurriculum = state.curriculums[state.curriculums.length - 1]
			const sequence = lastCurriculum.sequence + 1

			curriculumToAdd = {
				...lastCurriculum,
				courses: lastCurriculum.courses.map(a => ({ ...a })),
				curriculumHeader: this.getCurriculumHeader(frequency, sequence),
				errors: lastCurriculum.errors.map(e => ({ ...e })),
				frequency,
				id,
				sequence,
			}
		}

		state.curriculums.push(curriculumToAdd)
		this.processCurriculum(curriculumToAdd)
	},

	async copyCurriculumTrack(): Promise<void> {
		state.requestInProgress = true
		try {
			const params: ApiTrackCreateCopyPayload = {
				agency_id: getAgencyId(),
				name: state.selectedCurriculumTrack.name,
				source_curriculum_track_id: state.selectedCurriculumTrack.id ? state.selectedCurriculumTrack.id : '',
			}

			await postTrackCopy(api, params)

			const message = `"${state.selectedCurriculumTrack.name}" has been copied successfully.`

			GlobalStore.onHandleSuccessMessages({ message })
		} catch (error: any) {
			const message = `There was an error in copying "${state.selectedCurriculumTrack.name}".`

			GlobalStore.onHandleErrorMessages({ error: message })
			console.error(error)
		} finally {
			state.requestInProgress = false
		}
	},

	async deleteCurriculumTrack({
		agencyId, id, name,
	}: { agencyId: number, id: number, name: string }): Promise<void> {
		try {
			await deleteTrack(api, agencyId, id)
			this.hideDeletedCurriculumTrack(id)

			const message = `${name} has been successfully deleted!`

			GlobalStore.onHandleSuccessMessages({ message })
		} catch (error: any) {
			if (error.response.status === STATUS_CODE.INTERNAL_SERVER_ERROR) {
				const message = 'There was a problem with your request. Please try again later.'

				GlobalStore.onHandleErrorMessages({ error: message })

				return
			}

			GlobalStore.onHandleErrorMessages({ error })
		} finally {
			GlobalStore.closeModal()
		}
	},

	editCurriculumTrack(curriculum: CurriculumTrack): void {
		this.hideDeletedCurriculumTrack(curriculum.id)
		this.showAddedCurriculumTrack(curriculum)
	},

	formatMinutesAsHoursAndMinutesText(minutes: number, useAnd: boolean): string | undefined {
		const missingHours = Math.floor(minutes / TIME.MINUTES)
		const missingMinutes = Math.ceil(minutes % TIME.MINUTES)
		const minutesString = missingMinutes > 1 ? 'minutes' : 'minute'

		if (missingHours && missingMinutes) {
			return `${missingHours} hours${useAnd ? ' and' : ','} ${missingMinutes} ${minutesString}`
		} else if (missingHours) {
			return `${missingHours} hours`
		} else if (missingMinutes) {
			return `${missingMinutes} ${minutesString}`
		}
	},

	getCurriculumHeader(frequency: ComplianceFrequency | number | null, sequence: number): string {
		const currentFrequency = frequency === 'initial' ? 1 : 2

		return currentFrequency === state.annualFrequencyValue
			? `${capitalize(numberToWords.toWordsOrdinal(sequence))} Year ${GlobalStore.teamMemberText}s`
			: ''
	},

	async getCurriculums(): Promise<void> {
		if (state.curriculumId) {
			try {
				const params = {
					agency_id: getAgencyId(),
					curriculum_type: state.curriculumType,
					frequency: state.frequency,
				}

				const response = await postCurriculums(api, `/api/curriculum/courses/${state.curriculumId}`, params)

				if (Array.isArray(response)) {
					for (const curriculum of response) {
						this.processCurriculum(curriculum)
						state.curriculums.push(curriculum)
					}

					state.originalCurriculums = deepClone(state.curriculums)
					state.selectedCurriculum = state.curriculums[0]
					state.requirements = {
						...state.requirements,
						topicErrors: state.curriculums.reduce((acc, cur) => [...acc, ...cur.errors ?? []],
							[] as CurriculumError[]),
					}
				}
			} catch (error: any) {
				GlobalStore.onHandleErrorMessages({ error: GlobalStore.getAPIErrorMessage(error) })
			}
		}

		if (state.frequency === 'oneoff') {
			state.originalCurriculums = deepClone(state.curriculums)
		}
	},

	getFriendlyTotalDuration(courses: Course[]): string {
		const durationInMinutes = this.getTotalDuration(courses)
		const remainder = durationInMinutes % TIME.MINUTES
		const durationInHours = durationInMinutes / TIME.MINUTES

		return `${durationInHours}${remainder > 0 ? '+' : ''} hours`
	},

	getMissingTimeFromErrors(errors: CurriculumError[]): number {
		if (errors && errors.length) {
			for (let i = 0; i < errors.length; i++) {
				if (errors[i].type === 'time') {
					return errors[i].value as number
				}
			}
		}

		return 0
	},

	getTotalDuration(courses: Course[]): number {
		return courses.reduce((sum: number, course: Course) => sum + parseInt(course.duration_in_mins), 0)
	},

	goToStep(stepNum: number): void {
		state.currentStep = stepNum <= 0 ? 1 : stepNum
		// prevent currentStep from going to negative numbers (edge case)
	},

	hideDeletedCurriculum(curriculum: Curriculum): void {
		state.curriculums = state.curriculums.filter(c => c.id !== curriculum.id)
	},

	hideDeletedCurriculumTrack(id: number): void {
		state.curriculumTracks = state.curriculumTracks.filter(c => c.id !== id)
		GlobalStore.agency.curriculumTracks = state.curriculumTracks
	},

	isModifiedCurriculum(): boolean {
		const a = JSON.stringify(state.originalCurriculums.map(c => curriculumData(c)))
		const b = JSON.stringify(state.curriculums.map(c => curriculumData(c)))

		return a !== b || state.reordered
	},

	async makeDefaultCurriculum(curriculum: CurriculumTrack): Promise<void> {
		try {
			const params = {
				agency_id: getAgencyId(),
				curriculum_track_id: curriculum.id,
				is_default: curriculum.is_default = !curriculum.is_default,
				name: curriculum.name,
			}

			const response = await putTrackEdit(api, params)

			this.editCurriculumTrack(response.curriculum_track)

			const message = `${curriculum.name} is the default curriculum`

			GlobalStore.onHandleSuccessMessages({ message })
		} catch {
			const message = `There was an error in making ${curriculum.name} the default curriculum.`

			GlobalStore.onHandleErrorMessages({ message })
		}
	},

	next(): void {
		state.currentStep++
	},

	previous(): void {
		state.currentStep = state.currentStep <= 0 ? 1 : state.currentStep - 1
		// prevent currentStep from going to negative numbers (edge case)
	},

	processCurriculum(curriculum: Curriculum): void {
		const courses = curriculum.courses || []

		curriculum.curriculumHeader = this.getCurriculumHeader(curriculum.frequency, curriculum.sequence) ?? ''
		curriculum.duration = this.getTotalDuration(courses)
		curriculum.errors = [
			...this.verifyTimeRequirements(curriculum, state.requirements.time),
			...this.verifyTopicRequirements(courses, state.requirements.topics),
			...this.verifyNumberOfTopics(courses, state.requirements.topicNumber),
		]

		this.isModifiedCurriculum()
	},

	async publishCourses(): Promise<void> {
		try {
			if (!!state.deletedCurriculums) {
				this.removeEmptyCurriculums(state.curriculums, state.deletedCurriculums)
			}

			state.publishingCurriculum = true

			if (state.frequency === 'oneoff') {
				const params: ApiPublishOneoffCurriculumPayload = { course_ids: state.curriculums[0].courses.map(c => c.course_id) }

				await putOneoffPublishCurriculum(api, state.paths.update, params)

				const message = `${state.curriculums[0].name} has been updated.`

				GlobalStore.onHandleSuccessMessages({ message })
			} else {
				const params: ApiPublishCurriculumPayload = {
					agency_id: getAgencyId(),
					curriculums: JSON.stringify(state.curriculums) ?? '',
					deleted_curriculums: state.deletedCurriculums.length ?
						JSON.stringify(this.resequenceDeletedCurriculums(state.curriculums.length + 1, state.deletedCurriculums)) : [],
					user_id: GlobalStore.userId,
				}

				const response = await putPublishCurriculum(api, state.paths.update, params)

				state.curriculums = []

				for (const curriculum of response.curriculums) {
					this.processCurriculum(curriculum)
					state.curriculums.push(curriculum)
				}

				GlobalStore.onHandleSuccessMessages({ response })
			}

			GlobalStore.clearSelectedValues()
			GlobalStore.resetCAModal()

			state.originalCurriculums = deepClone(state.curriculums)
			state.reordered = false
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error: GlobalStore.getAPIErrorMessage(error) })
		} finally {
			state.publishingCurriculum = false
		}
	},

	removeEmptyCurriculums(curriculums: Curriculum[], deletedCurriculums: Curriculum[]): void {
		for (let i = 0; i < curriculums.length; i++) {
			if (curriculums[i].courses.length === 0) {
				deletedCurriculums.push(curriculums[i])
				curriculums.splice(i, 1)

				for (let j = i; j < curriculums.length; j++) {
					curriculums[j].sequence = j + 1
				}
			}
		}
	},

	async renameCurriculum(name: string): Promise<void> {
		state.requestInProgress = true

		try {
			const params = {
				agency_id: getAgencyId(),
				curriculum_track_id: state.selectedCurriculumTrack.id,
				is_default: state.selectedCurriculumTrack.is_default,
				name,
			}

			const response = await putTrackEdit(api, params)

			if ('curriculum_track' in response) {
				this.editCurriculumTrack(response.curriculum_track)
			}
		} catch {
			const message = `There was an error in renaming "${state.curriculumName}".`

			GlobalStore.onHandleErrorMessages({ error: message })
		} finally {
			state.requestInProgress = false
		}
	},

	resequenceDeletedCurriculums(start: number, deletedCurriculums: Curriculum[]): Curriculum[] {
		let sequence = start

		for (const curriculum of deletedCurriculums) {
			curriculum.sequence = sequence++

			// Remove courses and errors from deleted curriculums
			curriculum.courses = []
			curriculum.errors = []
		}

		return deletedCurriculums
	},

	resetData(): void {
		state.currentStep = 1
		state.curriculumCount = 0
		state.curriculumFrequency = ''
		state.curriculumId = null
		state.curriculumName = ''
		state.curriculumType = 0
		state.selectedCurriculumTrack = {} as CurriculumTrack
		GlobalStore.resetCAModal()
	},

	resetSchedulerData(): void {
		state.scheduleData = {
			classes_count: FREQUENCY.CLASSES_COUNT,
			frequency_count: FREQUENCY.COUNT,
			frequency_type: 'months',
		} as ScheduleData
	},

	showAddedCurriculumTrack(curriculum: CurriculumTrack): void {
		if (curriculum.is_default) {
			state.curriculumTracks.forEach(c => c.is_default = false)
			state.curriculumTracks.unshift(curriculum)
		} else {
			state.curriculumTracks.push(curriculum)
		}

		GlobalStore.agency.curriculumTracks = state.curriculumTracks
		state.selectedCurriculumTrack = curriculum
	},

	verifyNumberOfTopics(courses: Course[], numberOfTopics: number): CurriculumError[] {
		const errors: CurriculumError[] = []

		// Iterate over curriculum courses to get all curriculum's topics
		let topics: Topic[] = []

		for (let i = 0; i < courses.length; i++) {
			topics = topics.concat(courses[i].topics as Topic[])
		}

		// Get unique curriculum's topics
		const uniqueTopics = topics.filter(function (item, i, ar) {
			return item !== undefined && ar.indexOf(item) === i
		})

		// Check whether curriculum topics requirements are fulfilled or not
		if (uniqueTopics && uniqueTopics.length < numberOfTopics) {
			errors.push({
				type: 'topics-number',
				value: numberOfTopics - uniqueTopics.length,
			})
		}

		return errors
	},

	verifyTimeRequirements(curriculum: Curriculum, timeRequirements: number): CurriculumError[] {
		const errors: CurriculumError[] = []
		const duration = curriculum.duration ? curriculum.duration : 0

		if (timeRequirements * TIME.MINUTES > duration) {
			errors.push({
				type: 'time',
				value: timeRequirements * TIME.MINUTES - duration,
			})
		}

		return errors
	},

	verifyTopicRequirements(courses: Course[], topicRequirements: TopicRequirements[]): CurriculumError[] {
		const errors: CurriculumError[] = []

		if (topicRequirements) {
			// Iterate over each topic requirement to check if it is present in any of the curriculum courses
			for (let i = 0; i < topicRequirements.length; i++) {
				let isFound = false

				for (let j = 0; j < courses.length; j++) {
					const topics = courses[j].topics

					if (!Array.isArray(topics) || !topics.length) {
						continue
					}

					if (topics.find(topic => topic.id === topicRequirements[i].topic_id)) {
						isFound = true
						break
					}
				}

				// Topic is not found
				if (!isFound) {
					errors.push({
						id: topicRequirements[i].id,
						type: 'topic',
						value: topicRequirements[i].name,
					})
				}
			}
		}

		return errors
	},
}

function curriculumData(curriculum: Curriculum): ModifiedCurriculum {
	return {
		courseIds: curriculum.courses?.map(c => c.course_id),
		coursesLength: curriculum.courses?.length,
		sequence: curriculum.sequence,
	}
}

export default store

interface ModifiedCurriculum {
	courseIds: number[]
	coursesLength: number
	sequence: number
}
