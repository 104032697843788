import { textFieldErrorClass, textFieldSelector } from '@/javascript/vuejs/helpers/_constants'

import {
	type DateErrorType,
	defaultErrorMessages,
	validSeparatorsRegex,
} from './constants'

export function getSeparatorCharacterFromString(str: string): string | undefined {
	const match = str.match(validSeparatorsRegex)

	if (match) {
		return match[0]
	}
}

export function isStringOnlyDigits(str: string): boolean {
	return /^\d+$/.test(str)
}

export function createDateErrorObject({ errorType, formattedDate }: {
	errorType: DateErrorType,
	formattedDate?: string
}): DateError {
	return {
		errorType: errorType,
		formattedDate: formattedDate,
		hasError: true,
	}
}

export function createDateInfoObject({ formattedDate }: { formattedDate: string }): DateInfo {
	return {
		formattedDate: formattedDate,
		hasError: false,
	}
}

// show error message (depending on which kind of error)
export function triggerDateError({
	dateInfo, inputElem, minDateErrorMessage, maxDateErrorMessage,
}: TriggerDateErrorOptions): void {
	const textFieldElem = inputElem.closest<HTMLInputElement>(textFieldSelector)

	if (!textFieldElem) {
		return
	}

	textFieldElem.classList.add(textFieldErrorClass)

	const errorType = dateInfo.errorType
	let errorMessage = defaultErrorMessages[errorType]

	if (errorType === 'min' && minDateErrorMessage) {
		errorMessage = minDateErrorMessage
	}

	if (errorType === 'max' && maxDateErrorMessage) {
		errorMessage = maxDateErrorMessage
	}

	// if the error isn't with the format of the date string (i.e. min/max error), auto-format the string for the user
	if (errorType === 'min' || errorType === 'max') {
		inputElem.value = dateInfo.formattedDate ?? ''
	}

	insertErrorMessage(errorMessage, textFieldElem)
}

// remove error message & format date
export function triggerDateSuccess({ dateInfo, inputElem }: { dateInfo: DateInfo, inputElem: HTMLInputElement }): void {
	const textFieldElem = inputElem.closest(textFieldSelector)

	textFieldElem?.classList.remove(textFieldErrorClass)

	inputElem.value = dateInfo.formattedDate

	if (!dateInfo.hasError && !!inputElem) {
		const helperTextElem = inputElem.parentNode?.nextSibling

		helperTextElem?.remove()
	}
}

function insertErrorMessage(errorMessage: string, textFieldElem: HTMLInputElement): void {
	const helperTextElem = textFieldElem.parentNode?.querySelector<HTMLParagraphElement>('.mdc-text-field-helper-text')

	if (helperTextElem) {
		helperTextElem.innerText = errorMessage
	} else {
		const helperTextHTML = createHelperTextHTML(errorMessage)

		textFieldElem.insertAdjacentHTML('afterend', helperTextHTML)
	}
}

function createHelperTextHTML(errorMessage: string): string {
	return `<div class="mdc-text-field-helper-line">
    <div
      class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
      data-test="mdc-text-field-helper-text"
    >
      ${errorMessage}
    </div>
  </div>`
}

export interface DateError {
	errorType: DateErrorType
	formattedDate?: string
	hasError: true
}

export interface DateInfo {
	formattedDate: string
	hasError: false
}

interface TriggerDateErrorOptions {
	dateInfo: DateError
	inputElem: HTMLInputElement
	maxDateErrorMessage?: string
	minDateErrorMessage?: string
}
