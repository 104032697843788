import type { AxiosError, AxiosResponse } from 'axios'

import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

import type { ApiClient, ResponseData } from '../api'
import {
	apiRouteBuilder,
	getAvailableCertifications,
	getAvailableCourses,
	getCoursesOffline,
	postDisableCertification,
	postUpdateCourse,
	putAddCourses,
	putRenameCertification,
	putUnreleaseCertification,
	putWaiveCourses,
} from '../apiRoutes'
import type {
	GoalCourse,
	GoalCourseDetail,
	HistoricalCourse,
	OfflineCourse,
	TrainingGoal,
} from '../goals/interfaces'
import type { MemberForCourses } from '../members/interfaces'
import type { CourseData, MemberTrainingCourse } from '../memberTraining/interfaces'
import type {
	ActionableCourse,
	Certification,
	ComplianceFrequency,
} from './interfaces'

export async function getCertifications(api: ApiClient, agencyId: number): Promise<ApiCertificationsResponse> {
	const route = apiRouteBuilder(getAvailableCertifications, { agencyId: agencyId })
	const { data } = await api.get<ApiCertificationsResponse>(route)

	return data
}

export async function getCourses(api: ApiClient, payload: ApiCoursePayload): Promise<MemberTrainingCourse[]> {
	let route = ''

	if (payload.agencyId && payload.memberId) {
		route = apiRouteBuilder(getAvailableCourses, { agencyId: payload.agencyId }, { caregiver_id: payload.memberId })
	} else if (payload.franchisorId && GlobalStore.showFranchise) {
		route = `/franchisors/${payload.franchisorId}/available_courses`
	} else if (payload.agencyId) {
		route = apiRouteBuilder(getAvailableCourses, { agencyId: payload.agencyId })
	}

	const { data } = await api.get<MemberTrainingCourse[]>(route)

	return data
}

export async function getOfflineCourses(api: ApiClient, agencyId: number): Promise<OfflineCourse[]> {
	const route = apiRouteBuilder(getCoursesOffline, { agencyId })
	const { data } = await api.get<OfflineCourse[]>(route)

	return data
}

export async function postDeleteCertification(
	api: ApiClient,
	payload: ApiCertificationCourseIdPayload
): Promise<void> {
	await api.post(postDisableCertification, payload)
}

export async function postEditCourse(api: ApiClient, payload: ApiEditCoursePayload): Promise<void> {
	await api.post(postUpdateCourse, payload)
}

export async function postMoveCourse(api: ApiClient, path: string, payload: ApiMoveCoursePayload): Promise<ResponseData> {
	const response = await api.post<AxiosError | AxiosResponse>(path, payload)

	return response
}

export async function postResetReenrollCourse(api: ApiClient, path: string, payload: ApiResetReenrollCoursePayload): Promise<void> {
	await api.post<AxiosError | AxiosResponse>(path, payload)
}

export async function postResetCourseExamAttempts(
	api: ApiClient,
	path: string
): Promise<ResponseData> {
	const response = await api.post<AxiosError | AxiosResponse>(
		path
	)

	return response
}

export async function putEnrollCourses(
	api: ApiClient,
	agencyId: number,
	payload: ApiEnrollCoursesPayload
): Promise<ApiEnrollCoursesResponse> {
	const route = apiRouteBuilder(putAddCourses, { agencyId })
	const { data } = await api.put<ApiEnrollCoursesResponse>(route, payload)

	return data
}

export async function putHistoricalCourseCompletion(
	api: ApiClient,
	path: string,
	payload: ApiHistoricalCoursePayload
): Promise<ApiHistoricalCourseResponse> {
	const { data } = await api.put<ApiHistoricalCourseResponse>(path, payload)

	return data
}

export async function putRenameCertificationCourse(
	api: ApiClient,
	courseId: number,
	payload: ApiRenameCertificationCoursePayload
): Promise<void> {
	await api.put(`${putRenameCertification}/${courseId}`, { name: payload.name })
}

export async function putUnenrollCourse(api: ApiClient, payload: ApiUnwaiveOrUnenrollCoursePayload): Promise<void> {
	const path = `/agencies/${payload.agencyId}/caregivers/${payload.memberId}/unenroll_class`

	await api.put(path, { course_status_id: payload.courseStatusId, id: payload.memberId })
}

export async function putUnreleaseCertificationListCourse(
	api: ApiClient,
	payload: ApiCertificationCourseIdPayload
): Promise<void> {
	await api.put(putUnreleaseCertification, payload)
}

export async function putUnwaiveCourse(api: ApiClient, payload: ApiUnwaiveOrUnenrollCoursePayload): Promise<void> {
	const path = `/agencies/${payload.agencyId}/caregivers/${payload.memberId}/unwaive_class`

	await api.put(path, { course_status_id: payload.courseStatusId, id: payload.memberId })
}

export async function putWaiveCourse(api: ApiClient, agencyId: number, payload: ActionableCourse): Promise<ApiWaiveCourseResponse> {
	const route = apiRouteBuilder(putWaiveCourses, { agencyId })
	const { data } = await api.put<ApiWaiveCourseResponse>(route, payload)

	return data
}

export interface ApiCertificationCourseIdPayload {
	course_id?: number
	id?: number
}

export interface ApiCertificationsResponse {
	careAcademySpecializedCertifications: Certification[]
	specializedCertificationUnreleasePath: string
	specializedCertificationUpdatePath: string
}

export interface ApiCoursePayload {
	agencyId?: number
	franchisorId?: number | null
	memberId?: number | null
}

export interface ApiEnrollCoursesPayload {
	caregiver_ids: string
	course: {
		goal: TrainingGoal
		isModifiable: boolean
		is_external: boolean
	}
	external_courses: string
	selected_classes_ids: string
	selected_curriculum_ids: number[] | null
}

export interface ApiEnrollCoursesResponse {
	job_id: string
	message: string
}

export interface ApiHistoricalCoursePayload {
	historical: HistoricalCourse
}

export interface ApiHistoricalCourseResponse {
	content: {
		compliance_type: ComplianceFrequency
		course_status: GoalCourseDetail
		courses: GoalCourse[]
		goal_id: number
		statuses: GoalCourseDetail[]
	},
	errors: string[]
	message: string
	success: boolean
}

export interface ApiMoveCoursePayload {
	course_id: number | null
	new_goal_id: number | undefined
	old_goal_id: number | undefined
}

export interface ApiRenameCertificationCoursePayload {
	name: string
}

export interface ApiResetReenrollCoursePayload {
	course_status_id: number | null
	unenroll?: boolean
}

export interface ApiUnwaiveOrUnenrollCoursePayload {
	agencyId: number
	courseStatusId: number
	memberId: number
}

export interface ApiWaiveCourseResponse {
	agency_time_zone: string | null
	caregiver: MemberForCourses
	course_detail: GoalCourseDetail
	has_compliance_mandates: boolean
	is_in_a_compliance_goal: boolean
	is_super_user: boolean
	minimum_completion_date: string
	user_can_modify_compliance_goal: boolean
}

export type ApiEditCoursePayload = CourseData
