import Vue from 'vue'

import type { FranchisorAgencies } from '@/javascript/interfaces'

import api from '../services/api'
import { postAgencyStats } from '../services/franchisor'
import GlobalStore from './GlobalStore'

const state = Vue.observable({
	complianceReportLastPrintedAtCompleted: '',
	complianceReportLastPrintedAtIncomplete: '',
	courseStatusCompleted: '',
	courseStatusIncomplete: '',
	franchisorAgencies: [] as FranchisorAgencies[],
	franchisorId: null as number | null,
	franchisorName: '',
	hasErrors: false,
	isTeamFlowEnabled: false,
	loadingFranchisorAgencies: true,
	printComplianceReportFranchisorPathUrl: '',
	sinceAccountCreatedAt: '',
})

const store = {
	get complianceReportLastPrintedAtCompleted(): string {
		return state.complianceReportLastPrintedAtCompleted
	},
	set complianceReportLastPrintedAtCompleted(value) {
		state.complianceReportLastPrintedAtCompleted = value
	},

	get complianceReportLastPrintedAtIncomplete(): string {
		return state.complianceReportLastPrintedAtIncomplete
	},
	set complianceReportLastPrintedAtIncomplete(value) {
		state.complianceReportLastPrintedAtIncomplete = value
	},

	get courseStatusCompleted(): string {
		return state.courseStatusCompleted
	},
	set courseStatusCompleted(value) {
		state.courseStatusCompleted = value
	},

	get courseStatusIncomplete(): string {
		return state.courseStatusIncomplete
	},
	set courseStatusIncomplete(value) {
		state.courseStatusIncomplete = value
	},

	get franchisorAgencies(): FranchisorAgencies[] {
		return state.franchisorAgencies
	},
	set franchisorAgencies(value) {
		state.franchisorAgencies = value
	},

	get franchisorId(): number | null {
		return state.franchisorId
	},
	set franchisorId(value: number | null) {
		state.franchisorId = value
	},

	get franchisorName(): string {
		return state.franchisorName
	},
	set franchisorName(value) {
		state.franchisorName = value
	},

	get hasErrors(): boolean {
		return state.hasErrors
	},
	set hasErrors(value) {
		state.hasErrors = value
	},

	get isTeamFlowEnabled(): boolean {
		return state.isTeamFlowEnabled
	},
	set isTeamFlowEnabled(value: boolean) {
		state.isTeamFlowEnabled = value
	},

	get loadingFranchisorAgencies(): boolean {
		return state.loadingFranchisorAgencies
	},
	set loadingFranchisorAgencies(value) {
		state.loadingFranchisorAgencies = value
	},

	get printComplianceReportFranchisorPathUrl(): string {
		return state.printComplianceReportFranchisorPathUrl
	},
	set printComplianceReportFranchisorPathUrl(value) {
		state.printComplianceReportFranchisorPathUrl = value
	},

	get sinceAccountCreatedAt(): string {
		return state.sinceAccountCreatedAt
	},
	set sinceAccountCreatedAt(value) {
		state.sinceAccountCreatedAt = value
	},

	async loadFranchisorAgencies(): Promise<void> {
		try {
			const response: FranchisorAgencies[] = await postAgencyStats(api, { franchisor_id: this.franchisorId })

			this.franchisorAgencies = response
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
			this.hasErrors = true
		} finally {
			this.loadingFranchisorAgencies = false
		}
	},

	resetData(): void {
		this.franchisorName = ''
	},
}

export default store
