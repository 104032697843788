import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

import { getIdFromUrl } from './getIdFromUrl'

/**
 * Return id from url
 *   this is helpful when the agencyId or caregiverId is
 *   not in the store or access to store is unavailable
 *
 * @example getAgencyId()
 * @returns number
 */

export function getAgencyId(): number {
	return GlobalStore.currentUser?.agency?.id ?? GlobalStore.agency?.id ?? getIdFromUrl()
}
