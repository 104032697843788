import type { AxiosError, AxiosResponse } from 'axios'

import type { ApiClient, ResponseData } from '../api'
import {
	apiRouteBuilder,
	postCurriculumTrackCopy,
	postCurriculumTrackCreate,
	postCurriculumTrackDelete,
	postCurriculumTrackDuplicate,
	putCurriculumTrackEdit,
} from '../apiRoutes'
import type {
	AnnualTrainingSchedule,
	ComplianceFrequency,
	Curriculum,
	CurriculumTrack,
	ScheduleData,
} from '../courses/interfaces'

export async function deleteTrack(api: ApiClient, agencyId: number, curriculumTrackId: number): Promise<void> {
	const route = apiRouteBuilder(postCurriculumTrackDelete, { agencyId })

	await api.delete<AxiosError | AxiosResponse>(route, { curriculum_track_id: curriculumTrackId })
}

export async function postCurriculums(
	api: ApiClient,
	path: string,
	payload: ApiCurriculumsPayload
): Promise<ApiCurriculumsResponse> {
	const response = await api.post<ApiCurriculumsResponse>(path, payload)

	return response.data
}

export async function putOneoffPublishCurriculum(
	api: ApiClient,
	path: string,
	payload: ApiPublishOneoffCurriculumPayload
): Promise<void> {
	await api.put(path, payload)
}

export async function postTrackCopy(api: ApiClient, payload: ApiTrackCreateCopyPayload): Promise<ApiTrackCreateEditResponse> {
	const { data } = await api.post<ApiTrackCreateEditResponse>(postCurriculumTrackCopy, payload)

	return data
}

export async function postTrackCreate(api: ApiClient, payload: ApiTrackCreateCopyPayload): Promise<ApiTrackCreateEditResponse> {
	const { data } = await api.post<ApiTrackCreateEditResponse>(postCurriculumTrackCreate, payload)

	return data
}

export async function postTrackDuplicate(api: ApiClient, payload: ApiTrackCreateCopyPayload): Promise<ApiTrackCreateEditResponse> {
	const { data } = await api.post<ApiTrackCreateEditResponse>(postCurriculumTrackDuplicate, payload)

	return data
}

export async function putAdvancedOptions(
	api: ApiClient,
	path: string,
	payload: ApiAdvancedOptionsPayload
): Promise<ResponseData> {
	const response: ResponseData = await api.put<AxiosError | AxiosResponse, ApiAdvancedOptionsPayload>(path, payload)

	return response
}

export async function putAnnualScheduleChanges(
	api: ApiClient,
	path: string,
	payload: ApiAnnualScheduleChanges
): Promise<ResponseData> {
	const response: ResponseData = await api.put<AxiosError | AxiosResponse>(path, payload)

	return response
}

export async function putPublishCurriculum(
	api: ApiClient,
	path: string,
	payload: ApiPublishCurriculumPayload
): Promise<ApiCurriculumsResponse> {
	const response = await api.put<ApiCurriculumsResponse>(path, payload)

	return response.data
}

export async function putTrackEdit(api: ApiClient, payload: ApiTrackEditPayload): Promise<ApiTrackCreateEditResponse> {
	const { data } = await api.put<ApiTrackCreateEditResponse>(putCurriculumTrackEdit, payload)

	return data
}

export interface ApiAdvancedOptionsPayload {
	agency_id: number
	annual_compliance_due_date_base: AnnualComplianceBase
	custom_annual_due_date: Date | null
	initial_training_due_date_days: string | null
}

export interface ApiCurriculumsPayload {
	agency_id: number
	curriculum_type: number
	frequency: ComplianceFrequency | number | null
}

export interface ApiAnnualScheduleChanges {
	add_annual_courses_timing: AnnualTrainingSchedule
	agency_id: number
	schedule_data: ScheduleData
}

export interface ApiPublishCurriculumPayload {
	agency_id: number
	curriculums: string
	deleted_curriculums: string | []
	user_id: string
}

export interface ApiPublishOneoffCurriculumPayload {
	course_ids: number[]
}

export interface ApiTrackCreateCopyPayload {
	agency_id: number
	name: string
	source_curriculum_track_id: number | string
}

export interface ApiTrackEditPayload {
	agency_id: number
	curriculum_track_id: number
	is_default: boolean
	name: string
}

export type AnnualComplianceBase
	= ''
	| 'agency_custom_date'
	| 'birthday'
	| 'hire_date'
	| 'registration_date'
	| 'training_anniversary_date'

export interface ApiCurriculumsResponse {
	curriculums: Curriculum[]
	message: string
}

export interface ApiTrackCreateEditResponse {
	curriculum_track: CurriculumTrack
}
