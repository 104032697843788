import humps from 'humps'

import type { ApiClient } from '../api'
import { apiRouteBuilder, putGroupAssignment } from '../apiRoutes'
import type {
	Group,
	GroupAction,
	MoveMember,
} from './interfaces'

export async function createOrEditGroup(api: ApiClient, {
	action,
	id,
	isDefault,
	name,
	route,
}: { action: GroupAction, id: number, isDefault: boolean, name: string, route: string }): Promise<void> {
	const payload = {
		id,
		is_default: isDefault,
		name,
	}

	if (action === 'create') {
		await api.post(route, { ...payload, is_default: isDefault })
	} else if (action === 'rename') {
		await api.put(route, payload)
	}
}

export async function deleteMemberGroup(
	api: ApiClient,
	route: string,
	payload: ApiDeleteGroupPayload
): Promise<void> {
	await api.delete(route, payload)
}

export async function getGroups(api: ApiClient, agencyId: number): Promise<Group[]> {
	const path = `/api/caregiver_groups/${agencyId}`
	const { data } = await api.get<ApiGroupsResponse>(path)

	return data.groups
}

export async function putChangeGroupCurriculumTrack(
	api: ApiClient,
	route: string,
	payload: ApiChangeGroupCurriculumTrackPayload
): Promise<void> {
	await api.put(route, { caregiver_group_id: payload.groupId, curriculum_track_id: payload.curriculumTrackId })
}

export async function putMoveToGroup(api: ApiClient, moveMember: MoveMember): Promise<void> {
	const route = apiRouteBuilder(putGroupAssignment, { agencyId: moveMember.agencyId })

	await api.put(
		route,
		humps.decamelizeKeys({ caregiver_group_id: moveMember.groupId, caregiver_ids: moveMember.memberIds })
	)
}

export async function putAssignMemberToGroup(api: ApiClient, payload: MoveMember, route: string): Promise<void> {
	await api.put(route, humps.decamelizeKeys({
		agency_id: payload.agencyId,
		caregiver_group_id: payload.groupId,
		caregiver_ids: payload.memberIds,
	}))
}

export interface ApiChangeGroupCurriculumTrackPayload {
	curriculumTrackId: number
	groupId: number
}

export interface ApiDeleteGroupPayload { id: number }

export interface ApiGroupsResponse { groups: Group[] }
