/**
 * Return id from url
 *
 * @example getIdFromUrl()
 * @returns number
 */

export function getIdFromUrl(): number {
	const pathArray = window.location.pathname.split('/')

	return parseInt(pathArray[2])
}
