/* eslint-disable no-magic-numbers */
import type { ActionableCourse } from '../services/courses/interfaces'
import type { HistoricalCourse } from '../services/goals/interfaces'
import type { MemberTrainingCourse, WaiveData } from '../services/memberTraining/interfaces'

export const PAGINATION: {
	DEFAULT: number
	ITEMS: number
} = Object.freeze({
	DEFAULT: 1,
	ITEMS: 10,
})

export const ADVANCED_PLANS: {
	TIER_ONE: number
	TIER_TWO: number
	TIER_THREE: number
	TRAINING_MATERIALS_MINIMUM: number
} = {
	TIER_ONE: 125,
	TIER_TWO: 325,
	TIER_THREE: 375,
	TRAINING_MATERIALS_MINIMUM: 500,
}

export const CHARACTERS: {
	MAX: number
	MIN: number
	PASSWORD: number
	PHONE: { max: number, min: number }
	ZIP: { max: number, min: number }
} = Object.freeze({
	MAX: 500,
	MIN: 3,
	PASSWORD: 8,
	PHONE: { max: 14, min: 10 },
	ZIP: { max: 5, min: 5 },
})
export const COMPLETE = 100

export const COMPLETE_PLANS: {
	TIER_ONE: number
	TIER_TWO: number
	TIER_THREE: number
	TRAINING_MATERIALS_MINIMUM: number
} = {
	TIER_ONE: 150,
	TIER_TWO: 350,
	TIER_THREE: 400,
	TRAINING_MATERIALS_MINIMUM: 550,
}

export const COUNTDOWN = 4
export const CONFIG_HEADER = { headers: { Accept: 'application/json' } }
export const DEFAULT_SELECTED_CURRICULUM_NAME = '[Curriculum name]'
export const DELAY: {
	DEFAULT: number
	FAST: number
	NORMAL: number
	SLOWER: number
} = Object.freeze({
	DEFAULT: 2000,
	FAST: 100,
	NORMAL: 1000,
	SLOWER: 5000,
})

export const DESIRED_SEATS: {
	DECREMENT_VALUE: number
	INCREMENT_VALUE: number
	INITIAL_VALUE: number
	TIER_ONE: number
	TIER_TWO: number
	TIER_THREE: number
	UPDATED_LESS_THAN_30: number
	UPDATED_GREATER_THAN_30: number
} = {
	DECREMENT_VALUE: 25,
	INCREMENT_VALUE: 35,
	INITIAL_VALUE: 30,
	TIER_ONE: 25,
	TIER_TWO: 30,
	TIER_THREE: 35,
	UPDATED_LESS_THAN_30: 27,
	UPDATED_GREATER_THAN_30: 31,
}

export const DURATION_FILTER_DEFAULT = 120
export const DURATION_FILTER_STEP = 10
export const EMPTY_FIELD = { error: '', value: '' }
export const EMPTY_FIELD_ARRAY = { error: '', value: [] }
export const EMPTY_FIELD_NUMBER = { error: '', value: undefined }
export const EMPTY_FIELD_SELECT = { error: '', value: '0' }

export const ESSENTIALS_PLANS: {
	TIER_ONE: number
	TIER_TWO: number
	TIER_THREE: number
	TRAINING_MATERIALS_MINIMUM: number
} = {
	TIER_ONE: 100,
	TIER_TWO: 300,
	TIER_THREE: 350,
	TRAINING_MATERIALS_MINIMUM: 250,
}

export const FREQUENCY: {
	CLASSES_COUNT: number
	COUNT: number
} = Object.freeze({
	CLASSES_COUNT: 1,
	COUNT: 1,
})
export const INVALID_KEYS = [
	'-', 'e', '+', '.', 'ArrowUp', 'ArrowDown',
]
export const MOVE_TO = {
	DURATION: 200,
	DURATION_LONG: 400,
	TOLERANCE: 100,
}
export const PER_PAGE: {
	COURSES: number
	CURRENT: number
	DEFAULT: number
	ITEMS: number
	MEMBERS: number
	VIDEOS: number
} = Object.freeze({
	COURSES: 24,
	CURRENT: 1,
	DEFAULT: 100,
	ITEMS: 20,
	MEMBERS: 25,
	VIDEOS: 20,
})
export const PERCENTAGE_ERROR_TEXT = 'Must be a number between 0-100, or left blank'
export const SEAT_COUNT: {
	INCLUDED: number
	MAX: number
	MIN: number
	STEP: number
} = Object.freeze({
	INCLUDED: 25,
	MAX: 1000,
	MIN: 0,
	STEP: 5,
})
export const STATUS_CODE: {
	BAD_REQUEST: number
	FORBIDDEN: number
	INTERNAL_SERVER_ERROR: number
	OK: number
	NO_CONTENT: number
	NOT_FOUND: number
} = {
	BAD_REQUEST: 400,
	FORBIDDEN: 403,
	INTERNAL_SERVER_ERROR: 500,
	OK: 200,
	NO_CONTENT: 204,
	NOT_FOUND: 404,
}
export const TIME: {
	DAYS_IN_MONTH: number
	DAYS_IN_YEAR: number
	DAYS: number
	FLOOR: number
	MILLISECONDS: number
	MINUTES: number
	WEEKS: number
	YEAR: number
} = Object.freeze({
	DAYS_IN_MONTH: 31,
	DAYS_IN_YEAR: 365,
	DAYS: 7,
	FLOOR: 100,
	MILLISECONDS: 300,
	MINUTES: 60,
	WEEKS: 26,
	YEAR: 1970,
})
export const VALID_NUMERIC_KEYS: string[] = [
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'Backspace',
	'Delete',
	'Enter',
	'Escape',
	'Tab',
	'ArrowUp',
	'ArrowDown',
	'ArrowLeft',
	'ArrowRight',
]
export const Z_INDEX = 9999

// text field
const textFieldClass = 'mdc-text-field'
const textFieldSelector = '.mdc-text-field'
const textFieldErrorClass = 'mdc-text-field--invalid'
const textFieldErrorSelector = '.mdc-text-field--invalid'

// element hidden
const hiddenElemSelector = '.hide, .js-reveal--element-hidden, .dn'

export {
	hiddenElemSelector,
	textFieldClass,
	textFieldErrorClass,
	textFieldErrorSelector,
	textFieldSelector,
}

export function getDefaultCourse(): MemberTrainingCourse {
	return {
		add_to_library: false,
		courseStatusActions: {
			renaming: false,
			selected: false,
			type: 'courses',
		},
		course_status_id: null,
		description: '',
		id: null,
		is_external: false,
		length_in_minutes: undefined,
		limited_usage: false,
		name: '',
		topics: [],
		training: {
			completionDate: '',
			grade: undefined,
			location: '',
			organization: '',
			trainer: '',
		},
		type: '',
	}
}

export function getDefaultHistoricalCourse(): HistoricalCourse {
	return {
		added_in_bundle: false,
		caregiver_id: null,
		course_id: null,
		course_status_id: null,
		description: '',
		external_courses: [],
		goal: {
			due_date: '',
			is_new: false,
			saveClassList: false,
		},
		id: null,
		name: '',
		training: { organization: '' },
		type: undefined,
	}
}

export function getDefaultWaiveClass(): ActionableCourse {
	return {
		add_to_library: false,
		agency_id: 0,
		caregiver_ids: '',
		course: {
			description: '',
			finalGrade: undefined,
			name: '',
			training: {
				completedDate: '',
				date: '',
				grade: undefined,
				location: '',
				trainer: '',
			},
			type: 'none',
		},
		course_status_id: null,
	}
}

export function getDefaultWaiveData(): WaiveData {
	return {
		action: 'waive_class',
		caregiverId: null,
		courseData: {
			courseDesc: '',
			courseDuration: TIME.MINUTES,
			isLmsCourse: false,
		},

		courseName: '',
		courseStatusId: null,
		goalHasComplianceMandates: false,
		is_external: false,
		test: '',
		training: {
			completionDate: '',
			location: '',
			organization: '',
			trainer: '',
		},

		type: 'online_careacademy',
	}
}
/* eslint-enable no-magic-numbers */
