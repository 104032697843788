import type { FranchisorAgencies } from '@/javascript/interfaces'

import type { ApiClient } from '../api'
import { postFranchisorAgencyStats } from '../apiRoutes'

export async function postAgencyStats(api: ApiClient, payload: ApiStatsPayload): Promise<ApiFranchisorAgenciesResponse> {
	const { data } = await api.post<ApiFranchisorAgenciesResponse, ApiStatsPayload>(postFranchisorAgencyStats, payload)

	return data
}

export async function franchisorInfo(api: ApiClient, payload: ApiStatsPayload): Promise<any> {
	const { data } = await api.get<any>(`/api/franchisors/${payload.franchisor_id}/name`)

	return data
}
export interface ApiStatsPayload {
	franchisor_id: number | null
}

export type ApiFranchisorAgenciesResponse = FranchisorAgencies[]
export type FranchisorInfoResponse = any
